import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { Copyright, Link } from 'react-portal'
import imgNciLogo from '../../assets/images/nci-logo-full.svg'

const Footer = () => (
  <Row xs={1} md={2}>
    <Col md={12} lg={4}>
      <p>
        {'Contact Us: '}
        <Link url='mailto:nasdcsupport@osumc.edu'>nasdcsupport@osumc.edu</Link>
      </p>
      <p>
        <Copyright startYear={2020} />
        {' | '}
        <Link url='https://it.osu.edu/privacy'>Privacy Policy</Link>
      </p>
    </Col>
    <Col md={12} lg={4}>
      <p>
        <Image fluid src={imgNciLogo} alt='National Cancer Institute' />
      </p>
      <p>
        <strong>The NCI Awardee Skills Development Consortium</strong> is funded
        by the National Cancer Institute of the National Institutes of Health,
        through Cooperative Agreements
      </p>
    </Col>
  </Row>
)

export { Footer }

import { propTypes } from 'react-portal'
import { ApplicantPage } from '../'

const PageFactory = ({ category }) => {
  switch (category.categoryType) {
    case 'APPLICANT':
      return <ApplicantPage category={category} />
    default:
      return null
  }
}

PageFactory.propTypes = {
  category: propTypes.categoryPropType
}

export { PageFactory }

import { useAuth } from 'react-oauth2'
import { env, useStore } from 'react-portal'
import { useMutation } from 'react-query'
import { format } from 'date-fns'
import fileDownload from 'js-file-download'

export const useExportMutation = () => {
  const { securedFetch } = useAuth()
  const setDisabled = useStore((state) => state.setDisabled)
  const setError = useStore((state) => state.setError)
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)

  const getExport = async (categoryId) => {
    const url = encodeURI(
      `${env.apiUrl}/categories/${categoryId}/applicants/export`
    )
    const response = await securedFetch(url)
    if (!response.ok) {
      throw new Error(
        `Service returned ${response.status} ${response.statusText}`
      )
    }
    return response.blob()
  }

  return useMutation(({ categoryId }) => getExport(categoryId), {
    onMutate: () => {
      setDisabled(true)
      progressStart()
      setMessage('Downloading...')
    },
    onError: () => {
      setError('Error downloading document')
    },
    onSuccess: (data) => {
      fileDownload(
        data,
        `${format(new Date(), 'yyyyMMdd-HHmmss')}-applicants.csv`
      )
      setMessage('Document was downloaded')
    },
    onSettled: () => {
      setDisabled(false)
      progressEnd()
    }
  })
}

import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSortBy, useTable } from 'react-table'
import { Form, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretUp,
  faFileDownload,
  faFlag
} from '@fortawesome/free-solid-svg-icons'
import ReactPaginate from 'react-paginate'
import { format, parseISO } from 'date-fns'
import Textarea from 'react-expanding-textarea'

// https://react-table.tanstack.com/docs/examples/editable-data
const NotesInput = ({ column, row, value: initialValue, onUpdate }) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)

  const onChange = (e) => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    onUpdate(row.original.id, column.id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <Textarea
      aria-label='UE5 Notes'
      className='border-0'
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}

const ApplicantTable = ({
  applicants,
  page,
  pageCount,
  onPageChange,
  sort,
  onSort,
  onUpdate,
  onDownload
}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'ORCID',
        accessor: 'orcId',
        Cell: ({ value }) => (
          <a
            href={`https://orcid.org/${value}`}
            target='_blank'
            rel='noopener noreferrer'
            className='btn-link'
          >
            {value}
          </a>
        )
      },
      { Header: 'First Name', accessor: 'firstName' },
      { Header: 'Last Name', accessor: 'lastName' },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => (
          <a
            href={`mailto:${value}`}
            target='_blank'
            rel='noopener noreferrer'
            className='btn-link'
          >
            {value}
          </a>
        )
      },
      {
        Header: 'Application Date',
        accessor: 'applicationDate',
        Cell: ({ value }) => {
          try {
            return format(parseISO(value), 'MM/dd/yyyy')
          } catch (e) {
            console.error('Error parsing lastUpdated', value, e.stack)
            return ''
          }
        }
      },
      { Header: 'Course', accessor: 'course' },
      { Header: 'Choice', accessor: 'choice' },
      {
        id: 'accept',
        Header: 'Accept',
        accessor: 'status',
        Cell: ({ value }) => (
          <Form.Check
            className='d-flex justify-content-center'
            type='radio'
            aria-label='accept'
            readOnly
            checked={value === 'accept'}
          />
        ),
        disableSortBy: true
      },
      {
        id: 'waitlist',
        Header: 'Waitlist',
        accessor: 'status',
        Cell: ({ value }) => (
          <Form.Check
            className='d-flex justify-content-center'
            type='radio'
            aria-label='waitlist'
            readOnly
            checked={value === 'waitlist'}
          />
        ),
        disableSortBy: true
      },
      {
        id: 'deny',
        Header: 'Deny',
        accessor: 'status',
        Cell: ({ value }) => (
          <Form.Check
            className='d-flex justify-content-center'
            type='radio'
            aria-label='deny'
            readOnly
            checked={value === 'deny'}
          />
        ),
        disableSortBy: true
      },
      {
        Header: 'Flag',
        accessor: 'flag',
        Cell: ({ value }) =>
          value ? (
            <div className='d-flex justify-content-center'>
              <FontAwesomeIcon
                icon={faFlag}
                size='lg'
                className='text-primary'
              />
            </div>
          ) : (
            <div className='h-100 w-100' />
          )
      },
      {
        id: 'application',
        Header: 'Application',
        accessor: 'id',
        Cell: () => (
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              icon={faFileDownload}
              size='lg'
              className='text-primary'
            />
          </div>
        ),
        disableSortBy: true
      },
      {
        Header: 'UE5 Notes',
        accessor: 'notes',
        Cell: NotesInput,
        disableSortBy: true
      },
      {
        Header: 'Applicant Response',
        accessor: 'response'
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
    setSortBy
  } = useTable(
    {
      columns,
      data: applicants,
      initialState: { sortBy: sort },
      manualSortBy: true,
      onUpdate
    },
    useSortBy
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSortBy(sort), [sort])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onSort(sortBy), [sortBy])

  const handleClick = ({ column, row, value }) => {
    const item = row.original
    switch (column.id) {
      case 'accept':
        item.status !== 'accept' && onUpdate(item.id, 'status', 'accept')
        break
      case 'waitlist':
        item.status !== 'waitlist' &&
          onUpdate(row.original.id, 'status', 'waitlist')
        break
      case 'deny':
        item.status !== 'deny' && onUpdate(row.original.id, 'status', 'deny')
        break
      case 'flag':
        onUpdate(row.original.id, 'flag', !value)
        break
      case 'application':
        onDownload(row.original.document)
        break
      default:
    }
  }

  return (
    <>
      <Table
        bordered
        hover
        size='sm'
        className='applicant-table bg-white'
        {...getTableProps()}
      >
        <thead className='bg-primary text-white'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined })
                  )}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size='lg'
                        className='ml-1'
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size='lg'
                        className='ml-1'
                      />
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      onClick={() => handleClick(cell)}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        previousLabel='<'
        nextLabel='>'
        breakClassName='page-item'
        breakLinkClassName='page-link'
        onPageChange={onPageChange}
        forcePage={page}
        containerClassName='pagination justify-content-center'
        pageClassName='page-item'
        pageLinkClassName='page-link'
        activeClassName='active'
        previousClassName='page-item'
        nextClassName='page-item'
        previousLinkClassName='page-link'
        nextLinkClassName='page-link'
      />
    </>
  )
}

ApplicantTable.propTypes = {
  applicants: PropTypes.arrayOf(
    PropTypes.shape({
      orcId: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      applicationDate: PropTypes.string.isRequired,
      course: PropTypes.string.isRequired,
      choice: PropTypes.number.isRequired,
      status: PropTypes.string,
      flag: PropTypes.boolean,
      notes: PropTypes.string
    }).isRequired
  ).isRequired,
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      desc: PropTypes.bool
    }).isRequired
  ).isRequired,
  onSort: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired
}

export { ApplicantTable }

import React from 'react'
import ReactDOM from 'react-dom'
import { Portal, env } from 'react-portal'
import { Footer } from './components'
import { QueryClient, QueryClientProvider } from 'react-query'
import { PageFactory } from './pages'
import imgSrcLogin from './assets/images/login.png'
import imgSrcLogo from './assets/images/nasdc_logo_tag.svg'
import './assets/scss/main.scss'
import { AuthProvider } from 'react-oauth2'

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider
      authUrl={env.authUrl}
      tokenUrl={env.tokenUrl}
      redirectUrl={env.redirectUrl}
      logoutUrl={env.logoutUrl}
      clientId={env.clientId}
      scope={env.scope}
    >
      <Portal
        categoryRenderer={PageFactory}
        footerRenderer={Footer}
        {...{
          applicationName: 'NASDC',
          imgSrcLogin,
          imgAltLogin: '',
          imgSrcLogo,
          imgAltLogo: 'NASDC - The NCI Awardee Skills Development Consortium'
        }}
      />
    </AuthProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)

import { useEffect } from 'react'
import { useAuth } from 'react-oauth2'
import { env, useStore } from 'react-portal'
import { useQuery } from 'react-query'

const PER_PAGE = 20

export const useApplicants = (categoryId, page, sort) => {
  const { securedFetch } = useAuth()
  const setError = useStore((state) => state.setError)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)

  const getApplicants = async () => {
    const offset = (page - 1) * PER_PAGE
    const sortParams = sort.reduce((result, item) => {
      result += `&sort.${item.id}=${item.desc ? 'desc' : 'asc'}`
      return result
    }, '')
    const url = encodeURI(
      `${env.apiUrl}/categories/${categoryId}/applicants?max=${PER_PAGE}&offset=${offset}${sortParams}`
    )
    const response = await securedFetch(url)
    if (!response.ok) {
      throw new Error(
        `Service returned ${response.status} ${response.statusText}`
      )
    }
    return response.json()
  }

  const transform = (record) => {
    record.document.name = `${record.lastName}${record.firstName}-Application.pdf`
    return record
  }

  const query = useQuery(
    ['applicants', categoryId, page, sort],
    getApplicants,
    {
      enabled: !!categoryId,
      keepPreviousData: true
    }
  )

  useEffect(() => {
    if (query.status === 'loading') {
      progressStart()
    } else if (query.status === 'success') {
      progressEnd()
    } else if (query.status === 'error') {
      setError('Error getting applicants')
      progressEnd()
    }
  }, [progressEnd, progressStart, setError, query.status])

  return {
    applicants: query.data
      ? query.data.items.map((it) => transform(it))
      : undefined,
    pageCount: query.data ? Math.ceil(query.data.count / PER_PAGE) : undefined
  }
}

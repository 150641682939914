import { useAuth } from 'react-oauth2'
import { env, useStore } from 'react-portal'
import { useMutation, useQueryClient } from 'react-query'

export const useApplicantMutation = () => {
  const { securedFetch } = useAuth()
  const setError = useStore((state) => state.setError)
  const queryClient = useQueryClient()

  const updateApplicant = (id, property, value) => {
    const url = encodeURI(`${env.apiUrl}/applicants/${id}`)
    const body = {}
    body[property] = value
    return securedFetch(url, {
      method: 'PATCH',
      body: JSON.stringify(body)
    }).then((response) => {
      if (!response.ok) {
        throw new Error(
          `Service returned ${response.status} ${response.statusText}`
        )
      }
      return response.json()
    })
  }

  return useMutation(
    ({ id, property, value }) => updateApplicant(id, property, value),
    {
      onSettled: (data, error) => {
        if (error) {
          setError('Error updating applicant')
        } else {
          queryClient.invalidateQueries('applicants')
        }
      }
    }
  )
}

import { useState } from 'react'
import { propTypes, Toolbar, useGetDocumentMutation } from 'react-portal'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { ApplicantTable } from '../../components'
import {
  useApplicantMutation,
  useApplicants,
  useExportMutation
} from '../../hooks'

const ApplicantPage = ({ category }) => {
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState([])

  const { applicants, pageCount } = useApplicants(category.id, page, sort)
  const { mutate: updateApplicant } = useApplicantMutation()
  const { mutate: downloadApplication } = useGetDocumentMutation()
  const { mutate: exportApplicants } = useExportMutation()

  const handleUpdate = (id, property, value) =>
    updateApplicant({ id, property, value })

  const handleDownload = (file) => downloadApplication({ file })

  const handlePageChange = (data) => setPage(data.selected + 1)

  const handleExport = () => exportApplicants({ categoryId: category.id })

  return (
    <>
      <Toolbar title={category.name}>
        <Button onClick={handleExport} title='Export' variant='link'>
          Export <FontAwesomeIcon icon={faFileDownload} size='sm' />
        </Button>
      </Toolbar>
      {applicants && (
        <ApplicantTable
          applicants={applicants}
          page={page - 1}
          pageCount={pageCount}
          onPageChange={handlePageChange}
          sort={sort}
          onSort={setSort}
          onUpdate={handleUpdate}
          onDownload={handleDownload}
        />
      )}
    </>
  )
}

ApplicantPage.propTypes = {
  category: propTypes.categoryPropType
}

export { ApplicantPage }
